export const chinese = {

    //menu
    home: "主页",
    dashboard: "面板",
    data: "数据",
    orders: "订单",
    delivery_order: "交货订单",
    invoice: "发票",
    master_data: "基础数据",
    client: "客户",
    supplier:"供应商",
    product: "产品",
    category: "产品类型",
    uom: "产品单位",
    inventory: "库存",
    product_inventory: "产品库存",
    inventory_transactions: "库存流水",
    configuration: "配置",
    settings: "基础设置",
    currency: "币种",
    terms: "订单条款",
    term_type: "条款类型",
    company: "管理公司",
    company_profile: "公司基础信息",
    company_users: "公司用户",
    admin_area: "管理",

    //system admin menu, no translation required

    system_admin_area: "System Admin Area",
    tenant_maintenance: "Tenant Maintenance",
    invoice_do: "Invoice/Do",
    tenant_management: "Tenant Management",
    tenant: "Tenant",
    users: "Users",
    api_configuration: "API Configuration",
    subscription_package: "Subscription Package",
    servicegroup: "Servicegroup",
    services: "Services",
    scheduler: "Scheduler",
    scheduler_log: "Scheduler Log",

    //header
    all_notification: "全部通知",
    hi: "你好",
    account_settings: "账户设置",
    logout: "登出",
    support_sent_message: "客户支持请求已发送，我们会尽快回复至您的邮件。",
    support_request: "客户支持",
    support_request_info: "让我们知道如何支持您的请求; 也欢迎所有的建议.",
    more_contents_error: "请输入更多内容",

    //generic
    ok: "好",
    to: "用来",
    yes: "是",
    no: "否",
    create: "创建",
    cancel: "取消",
    delete: "删除",
    update: "更新",
    unknow_error: "未知错误，请联系我们的客户支持.",
    data_failed_to_load: "数据无法加载.",
    please_confirm: "请确认.",
    invalid_email_format: "Email格式错误.",
    rows_per_page: "每页行数",
    record_delete: "记录已删除.",
    record_created: "记录已创建.",
    record_updated: "记录已更新.",
    record_delete_message: "您即将删除一条记录，请确认.",
    saved: "已保存",

    //create tenant
    company_created: "公司已经创建好了！",
    company_created_prompt: "公司已经创建好了！请重新登陆！",
    create_company: "创建您的公司",
    create_company_first_step: "数字化的第一步",
    create_company_fill_info: "填写取消的信息",
    create_company_in_eceipt: "在Eceipt创建您的公司",
    create_company_manage_order: "管理单据",
    create_company_manage_inventory: "管理库存",
    create_company_save_cost: "削减开支",
    company_name: "公司名",
    tenant_code_exist: "公司代码已存在",
    company_code: "公司代码",
    company_introduction: "公司介绍",
    representative: "代表人",
    business_registration_number: "商业登记号",
    contact_email: "联系人Email",
    contact_number: "联系号码",
    fax: "传真",
    address_line_1: "地址行 1",
    address_line_2: "地址行 2",
    address_line_3: "地址行 3",
    address_line_4: "地址行 4",
    country: "国家",
    postcode: "邮政编码",

    //table column name
    id: "Id",
    nr: "Nr",
    "on hand": "库存",
    name: "名称",
    description: "描述",
    quantity: "数量",
    unit: "单位",
    remark: "备注",
    date: "日期",
    message: "信息",
    total: "总数",
    unitprice: "单价",
    safetyStockLevel: "安全库存",
    safetystocklevelInfo:"将安全库存设置为大于零以显示在主页面板中.",
    type: "类型",
    shortname: "名称(简)",
    movement: "变动",
    completed_on: "时间",
    do: "交货单",
    by: "执行",
    firstname: "名",
    lastname: "姓",
    roles: "角色",
    provider: "提供商",
    mode: "模式",
    amount: "金额",
    onhand: "现有",
    reserved: "保留",
    in_transit: "运输中",

    //dashboard
    overdue: "过期",
    outstanding_invoice: "未结发票",
    dashboard_of: "在",
    outstanding_delivery_order: "未完成交货订单",
    invoice_history: "发票历史",
    order_count: "订单数量",
    order_amount: "订单金额",
    delivery_order_history: "交货订单历史",
    normal: "正常",
    low: "低",
    nostock: "无库存",
    code: "编号",
    status: "状态",


    //orser status
    ALL: "全部",
    DRAFT: '草稿',
    GENERATED: '已生成',
    INVOICED: '开具发票',
    SETTLED: '已结算',
    CANCELLED: '取消',
    TRANSIT: '运送中',
    DELIVERED: '已交付',
    ORDER: "货单",
    ADJUSTMENT: "调整",

    //orders
    transaction_success: "成功.",
    generate_pdf_success: "PDF 生成成功.",
    resend_email_success: "邮件重发成功.",
    confirm_delivery_order1: "您即将确认向客户发货.",
    confirm_delivery_order2: "一旦确认,以后将无法更改交货订单内容.",
    confirm_resend_email1: "您即将向客户重新发送 PDF 单据.",
    confirm_resend_email2: "邮件将发送到客户邮箱地址, 如果您想添加更多邮箱地址, 请在下面输入. (以 逗号 , 分隔)",
    confirm_goods_received: "请确认已收到的所有货物.",
    ship: "发货",
    ship_send_email: "发货并发送电子邮件",
    email: "电子邮件",
    send: "发送",
    received: "已收到",
    cancel_order_warning: "您即将取消订单, 订单一旦取消, 将无法恢复.",
    cancel_order: "取消订单",
    manage_delivery_order: "管理交货单",
    search_order_number: "搜索订单号",

    order_number: "单号",
    order_to: "至",
    delivery_date: "送货日期",

    view_pdf: "查看PDF",
    pdf_generating: "PDF正在生成",
    regenerate_pdf_message: "如果没有生成PDF,请点击重新生成",
    action: "动作",
    edit: "编辑",
    revise: "修改",
    invoice_send_email:"开具发票并发送电子邮件",
    receive: "接收",
    update_linked_invoice: "修改关联发票",
    data_preview_alert: "仅数据预览, 实际PDF将具有不同的格式",
    from: "来自",
    phone: "电话",
    brn: "商业登记号",
    attn_to: "联系人",
    invoice_number: "关联发票",
    total_quantity: "总数量",

    loading_company_info: "加载公司信息",
    loading_existing_client: "加载现有客户端",
    loading_existing_open_invoice: "加载现有的未结发票",
    do_saved: "配送订单已保存",
    fill_all_field_warning: "请填写所有字段",
    finalise_do_message: "您正在敲定此交货订单, 请在敲定此订单之前确认所有信息是否正确.",
    generate_order_number_message: "订单号将生成, 且无法重新分配.",
    finalize: "最终确定",
    create_delivery_order: "创建交货单",
    linked_invoice_number: "链接的发票编号",
    none: "无",
    select_client: "选择客户",
    add_product: "添加产品",
    add_terms: "添加条款",
    save_draft: "保存草稿",
    preview_pdf: "预览 PDF",
    remarks: "备注",
    linked_invoice: "链接发票",
    do_inv_mismatch_error: "交货单数量大于发票数量,请检查关联的交货单",
    current_do: "当前交货单",
    previous_do: "之前交货单",
    invoice_to_client_message1: "您即将向客户开具发票, PDF发票 将会在电子邮件中发送给客户.",
    invoice_to_client_message2: "一旦开具发票, 以后将无法更改内容.",
    payment_received_confirmation: "请确认收到的所有付款.",
    paid: "已付款",
    manage_invoice: "管理发票",
    invoice_date: "发票日期",
    resend_email: "重发邮件",
    generate_delivery_order: "生成交货单",
    create_invoice: "创建发票",
    payment_due_date: "付款到期日",
    total_amount: "总金额",
    Invoice_saved: "发票已保存.",
    Finalise_invoice_message: "您正在敲定此交货订单，请在敲定此订单之前确认所有信息是否正确.",
    enable_inv_trans: "启用库存",
    enable_inv_trans_msg: "启用此选项以便生成库存流水",
    invoice_multi_currency_error:"一张发票只能使用一种货币!",

    //master
    client_Name: "客户名称",
    client_short_Name: "客户名称(简)",
    manage_client: "管理客户",
    manage_product: "管理产品",
    manage_category: "管理产品类型",
    manage_uom: "管理产品单位",
    manage_currency: "管理币种",
    manage_terms: "管理条款",
    manage_term_type: "管理条款类型",
    supplier_Name: "供应商名称",
    supplier_short_Name: "供应商简称",
    manage_supplier: "管理供应商",
    communication_type:"系统对接方式",

    //inventory
    manage_product_inventory: "管理产品库存",
    number_more_than_zero_error: "数量应该大于零",
    inventory_adjustment: "库存调整",
    in: "入",
    out: "出",
    adjust: "调整",
    transaction_added: "已调整.",
    view_product_inventory: "查看产品库存",
    search_product_code: "搜索产品代码",

    //settings
    platform_setting: "平台设置",
    document_language: "文档语言",
    english: "英语",
    chinese: "中文",
    language_setting_message: "此为生成PDF所使用的语言.",
    order_setting: "单据设置",
    invoice_order_format: "发票订单格式",
    preview: "预览",
    delivery_order_format: "送货订单格式",
    sequence: "序列",
    pdf_format: "PDF格式",
    style_1: "样式1",
    style_2: "样式2",
    attention:"请注意",
    save_setting_message:'使用不同的发票格式保存会重置订单顺序,导致发票订单中的记录不一致.',

    //User Profile
    user_info_updated: "用户信息已更新.",
    image_updated: "图片已更新.",
    image_remove: "图片已更新。",
    password_updated: "密码已更新.",
    password_strength_warning: "密码强度太低.",
    file_size_too_big_error: "文件太大. 请使用小于 2Mb 的图像.",
    remove_image_prompt: "您即将删除图片，请确认.",
    change_password: "更改密码",
    change_password_google_message: "*对于google登录用户，您可以使用忘记密码功能来创建新密码",
    old_password: "旧密码",
    new_password: "新密码",
    password_requirement: "密码格式无效. (最少 8 个字符，混合数字/大写/小写/特殊字符)",
    update_user_info: "更新用户信息",
    user_profile: "个人资料",
    verifed: "已验证",
    recent_login_on: "最近登录",
    login_count: "登录次数",

    //manage company
    user_invited: "用户已邀请.",
    user_removed: "用户已移除.",
    user_removed_message: "您即将从公司中移除用户, 请确认.",
    remove: "移除",
    invite_user: "邀请用户",
    invite_user_message: "*一封电子邮件将发送到该电子邮件地址，用户接受邀请后将加入公司",
    invite: "邀请",
    password: "密码",
    user: "普通用户",
    tenant_admin: "管理员",
    remove_user_from_tenant: "从公司中移除用户",
    update_company_info: "更新公司信息",
    owner: "所有者",
    owner_firstname: "所有者名字",
    owner_lastname: "所有者姓氏",
    company_info: "公司信息",
    upload_company_logo: "上传公司徽标",
    owner_email: "所有者电子邮件",
    introduction: "简介",
    subscription_cancelled: "订阅已取消.",
    subscription_cancel_message: "您即将取消订阅.",
    company_subscription: "公司订阅",
    subscribe: "订阅",
    extend_expiry_date: "延长到期日期",
    expire_date: "过期日期",
    health: "健康",
    payment_provider: "支付提供商",
    payment_mode: "支付方式",
    one_time_payment: "一次性付款",
    subscription:"订阅",
    recurring_payment_status: "定期付款状态",
    cancel_subscription: "取消订阅",
    payment_date: "付款日期",
    customer_email: "客户邮箱",
    customer_id: "客户 ID",
    subscription_id: "订阅 ID",
    monthly: "每月",
    transaction_history: "交易历史",
    success: "成功",

    platform_announcement:"平台公告",
    view:"查看",
}